var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'ChefContestForm pb-l',
    {
      hasCustomFont: _vm.dataHasCustomFont !== false,
    } ]},[_c('div',{staticClass:"container pt-r pb-r"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 text-center"},[_c('cta-button-cmp-extended',{staticClass:"btn-text text-color-grey-600 a-caption backBtn",attrs:{"data-url":_vm.dataBackPageUrl,"data-aria-label":_vm.dataBackPageLabel}},[_vm._v(" "+_vm._s(_vm.dataBackPageLabel)+" "),_c('template',{slot:"before-icon"},[_c('svg',[_c('use',{attrs:{"href":"#icon-caret-left"}})])])],2)],1)])]),_c('hero-cmp',{staticClass:"image pt-r noFixedHeight pb-r"},[_c('template',{slot:"type"},[_vm._t("overtitle")],2),_c('template',{slot:"title"},[_vm._t("title")],2),_c('template',{slot:"breadcrumbs"},[_vm._t("subtitle")],2)],2),_c('div',{staticClass:"container"},[_c('form-cmp',{staticClass:"ContactFormFiscalInfoCmp"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10 col-xl-8"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"name","data-placeholder":_vm.placeholders.name,"data-error-occurred":_vm.$v.name.$error,"data-errors":[
                  {
                    condition: !_vm.$v.name.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.name.serverError,
                    message: _vm.serverErrors.name,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("nameLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.name.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"lastName","data-placeholder":_vm.placeholders.lastName,"data-error-occurred":_vm.$v.lastName.$error,"data-errors":[
                  {
                    condition: !_vm.$v.lastName.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.lastName.serverError,
                    message: _vm.serverErrors.lastName,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("lastNameLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.lastName.$model),callback:function ($$v) {_vm.$set(_vm.$v.lastName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.lastName.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('select-cmp-extended',{attrs:{"data-name":"market","data-option-list":_vm.markets,"data-placeholder":_vm.placeholders.market,"data-error-occurred":_vm.$v.market.$error,"data-errors":[
                  {
                    condition: !_vm.$v.market.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.market.serverError,
                    message: _vm.serverErrors.market,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("marketLabel")]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true}],null,true),model:{value:(_vm.$v.market.$model),callback:function ($$v) {_vm.$set(_vm.$v.market, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.market.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"city","data-placeholder":_vm.placeholders.city,"data-error-occurred":_vm.$v.city.$error,"data-errors":[
                  {
                    condition: !_vm.$v.city.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.city.serverError,
                    message: _vm.serverErrors.city,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("cityLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.city.$model),callback:function ($$v) {_vm.$set(_vm.$v.city, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.city.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"email","data-placeholder":_vm.placeholders.email,"data-error-occurred":_vm.$v.email.$error,"data-errors":[
                  {
                    condition: !_vm.$v.email.email,
                    message: _vm.dataValidEmail,
                  },
                  {
                    condition: !_vm.$v.email.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.email.serverError,
                    message: _vm.serverErrors.email,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("emailLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"confirmEmail","data-placeholder":_vm.placeholders.confirmEmail,"data-error-occurred":_vm.$v.confirmEmail.$error,"data-errors":[
                  {
                    condition: !_vm.$v.confirmEmail.sameAs,
                    message: _vm.dataSameEmail,
                  },
                  {
                    condition: !_vm.$v.confirmEmail.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.confirmEmail.serverError,
                    message: _vm.serverErrors.confirmEmail,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("confirmEmailLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.confirmEmail.$model),callback:function ($$v) {_vm.$set(_vm.$v.confirmEmail, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.confirmEmail.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"phone","data-placeholder":_vm.placeholders.phone,"data-error-occurred":_vm.$v.phone.$error,"data-errors":[
                  {
                    condition: !_vm.$v.phone.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.phone.serverError,
                    message: _vm.serverErrors.phone,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("phoneLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.phone, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.phone.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"restaurant","data-placeholder":_vm.placeholders.restaurant,"data-error-occurred":_vm.$v.restaurant.$error,"data-errors":[
                  {
                    condition: !_vm.$v.restaurant.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.restaurant.serverError,
                    message: _vm.serverErrors.restaurant,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("restaurantLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.restaurant.$model),callback:function ($$v) {_vm.$set(_vm.$v.restaurant, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.restaurant.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"website","data-placeholder":_vm.placeholders.website,"data-error-occurred":_vm.$v.website.$error,"data-errors":[
                  {
                    condition: !_vm.$v.website.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.website.serverError,
                    message: _vm.serverErrors.website,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("websiteLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.website.$model),callback:function ($$v) {_vm.$set(_vm.$v.website, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.website.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('input-text-cmp-extended',{attrs:{"data-name":"position","data-placeholder":_vm.placeholders.position,"data-error-occurred":_vm.$v.position.$error,"data-errors":[
                  {
                    condition: !_vm.$v.position.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.position.serverError,
                    message: _vm.serverErrors.position,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("positionLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.position.$model),callback:function ($$v) {_vm.$set(_vm.$v.position, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.position.$model"}})],1),_c('div',{staticClass:"mt-7 col-12"},[_c('textarea-cmp-extended',{attrs:{"data-name":"recipe","data-placeholder":_vm.placeholders.recipe,"data-rows":7,"data-resizable":false,"data-error-occurred":_vm.$v.recipe.$error,"data-errors":[
                  {
                    condition: !_vm.$v.recipe.serverError,
                    recipe: _vm.serverErrors.recipe,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("recipeLabel")]},proxy:true}],null,true),model:{value:(_vm.$v.recipe.$model),callback:function ($$v) {_vm.$set(_vm.$v.recipe, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.recipe.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"},[_c('select-cmp-extended',{attrs:{"data-name":"how","data-option-list":_vm.hows,"data-placeholder":_vm.placeholders.how,"data-error-occurred":_vm.$v.how.$error,"data-errors":[
                  {
                    condition: !_vm.$v.how.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.how.serverError,
                    message: _vm.serverErrors.how,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("howLabel")]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-caret-down"}})])]},proxy:true}],null,true),model:{value:(_vm.$v.how.$model),callback:function ($$v) {_vm.$set(_vm.$v.how, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.how.$model"}})],1),_c('div',{staticClass:"mt-7 col-12 col-md-6"}),_c('div',{staticClass:"mt-9 col-12"},[_c('wysiwyg-cmp',[_c('div',{staticClass:"text-color-blue n-heading-3"},[_vm._t("uploadTitle")],2),_c('div',{staticClass:"styledLists pt-s"},[_vm._t("uploadSubtitle")],2)])],1),_c('div',{staticClass:"mt-9 col-12"},[_c('input-file-cmp-extended',{ref:"$file",staticClass:"dropbox",attrs:{"data-name":"file","data-error-occurred":_vm.$v.file.$error,"data-errors":[
                  {
                    condition: !_vm.$v.file.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.file.validSize,
                    message: _vm.dataValidSize,
                  },
                  {
                    condition: !_vm.$v.file.validFormat,
                    message: _vm.dataValidFormat,
                  },
                  {
                    condition: !_vm.$v.file.serverError,
                    message: _vm.serverErrors.file,
                  } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("fileLabel")]},proxy:true},{key:"icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-upload"}})])]},proxy:true},{key:"drag-text",fn:function(){return [_vm._t("fileLabelDrag"),_c('cta-button-cmp-extended',{staticClass:"btn-text"},[_vm._t("fileLabelCta")],2)]},proxy:true},{key:"drop-text",fn:function(){return [_vm._t("fileLabelDrop")]},proxy:true}],null,true),model:{value:(_vm.$v.file.$model),callback:function ($$v) {_vm.$set(_vm.$v.file, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.file.$model"}},[[_vm._t("fileLabelPlaceholder")]],2)],1),_c('div',{staticClass:"mt-9 col-12"},[_c('input-checkbox-cmp-extended',{staticClass:"custom direction-column",attrs:{"data-checkbox-list":[
                  {
                    labelOn: _vm.dataConsentFirst,
                    labelOff: '	',
                  } ],"data-name":"checkbox1","data-error-occurred":_vm.$v.consent1.$invalid,"data-errors":[
                  {
                    condition: !_vm.$v.consent1.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.consent1.serverError,
                    message: _vm.serverErrors.consent1,
                  } ]},model:{value:(_vm.consent1),callback:function ($$v) {_vm.consent1=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"consent1"}})],1),_c('div',{staticClass:"col-12"},[_c('input-checkbox-cmp-extended',{staticClass:"custom direction-column",attrs:{"data-name":"checkbox2","data-checkbox-list":[
                  {
                    labelOn: _vm.dataConsentSecond,
                    labelOff: '	',
                  } ],"data-error-occurred":_vm.$v.consent2.$invalid,"data-errors":[
                  {
                    condition: !_vm.$v.consent2.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.consent2.serverError,
                    message: _vm.serverErrors.consent2,
                  } ]},model:{value:(_vm.consent2),callback:function ($$v) {_vm.consent2=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"consent2"}})],1)]),_c('div',{staticClass:"row pt-r justify-content-center"},[_c('div',{staticClass:"col-auto"},[_c('recaptcha-cmp',{attrs:{"data-sitekey":_vm.dataRecaptchaApiKey,"data-aria-label":"recaptcha","data-error-occurred":_vm.$v.recaptcha.$error,"data-errors":[
                  {
                    condition: !_vm.$v.recaptcha.required,
                    message: _vm.dataRequiredField,
                  },
                  {
                    condition: !_vm.$v.recaptcha.serverError,
                    message: _vm.serverErrors.recaptcha,
                  } ]},on:{"verify":_vm.recaptchaVerify}})],1)]),_c('div',{staticClass:"row mt-7"},[_c('div',{staticClass:"col-12 text-center"},[_c('submit-cmp-extended',{attrs:{"data-theme-classes":['reversed'],"data-disabled":_vm.disableSubmit || _vm.submitted,"data-error-occurred":!!_vm.globalErrors.length || !!Object.keys(_vm.serverErrors).length,"data-errors":_vm.globalErrors},on:{"click-submit":_vm.doSubmit},scopedSlots:_vm._u([{key:"after-icon",fn:function(){return [_c('svg',[_c('use',{attrs:{"href":"#icon-arrow-right"}})])]},proxy:true}])},[[_vm._t("submitLabel")]],2)],1)]),_c('div',{staticClass:"row mt-9"},[_c('div',{staticClass:"col-12"},[_vm._t("requiredFieldsLabel")],2)])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }