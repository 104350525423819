<template>
  <section :class="['ContactFormCmp', { loading: !ready }]">
    <div v-if="ready" class="views">
      <transition name="view" mode="out-in">
        <router-view class="view" />
      </transition>
    </div>
  </section>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ContactFormCmp',
  props: {
    dataContactUsUrl: {
      type: String,
      required: true,
    },
    dataRecaptchaApiKey: {
      type: String,
      required: true,
    },
    dataContactFormApiUrl: {
      type: String,
      required: true,
    },
    dataViews: {
      type: [Array, String],
      required: true,
    },
    dataContentData: {
      type: [Object, String],
      required: true,
    },
  },
  setup() {
    return { castPropToType };
  },
  data() {
    return {
      storeViewsReady: false,
      storeContentDataReady: false,
    };
  },
  computed: {
    ...mapState('gigya', ['active', 'loaded']),
    views() {
      return this.dataViews
        ? this.castPropToType(this.dataViews, 'array')
        : null;
    },
    contentData() {
      return this.dataContentData
        ? this.castPropToType(this.dataContentData, 'object')
        : null;
    },
    ready() {
      const gigyaReady = this.active ? this.loaded : true;
      return this.storeViewsReady && this.storeContentDataReady && gigyaReady;
    },
  },
  watch: {
    ready: {
      handler(val) {
        if (val) {
          this.setReady(val);
        }
      },
      immediate: true,
    },
  },

  created() {
    this.$store.dispatch('setProperties', {
      module: 'contact',
      payload: { contactUsUrl: this.dataContactUsUrl },
    });
    this.$store.dispatch('setProperties', {
      module: 'contact',
      payload: { contactFormApiUrl: this.dataContactFormApiUrl },
    });
    this.$store.dispatch('setProperties', {
      module: 'contact',
      payload: { recaptchaApiKey: this.dataRecaptchaApiKey },
    });
    //violo il paradigma poichè ho la necessità di impostare i valori
    //in maniera sincrona
    this.$store.commit('SET_PROPERTIES', {
      module: 'contact',
      property: 'views',
      value: [...this.views],
    });
    this.storeViewsReady = true;
    this.$store.commit('SET_PROPERTIES', {
      module: 'contact',
      property: 'contentData',
      value: { ...this.contentData },
    });

    Promise.all([this.getCustomers(), this.getReasons()])
      .then(() => (this.storeContentDataReady = true))
      .catch((e) => {
        // eslint-disable-next-line
        console.error(e);
      });
  },
  methods: {
    ...mapActions('contact', ['setReady', 'getCustomers', 'getReasons']),
  },
};
</script>

<style lang="scss" scoped>
.ContactFormCmp {
  min-height: 150px;

  &.loading {
    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw !important;
      height: 100vh !important;
      background: rgba(0, 0, 0, 0.2);
      z-index: map_get($z, overlay);
    }

    &:after {
      content: '';
      display: block;
      position: fixed;
      top: 50%;
      left: 50%;
      width: 48px !important;
      height: 48px !important;
      min-width: 0;
      min-height: 0;
      color: rgba(255, 255, 255, 1);
      border: 5px solid;
      border-right-color: rgba(0, 0, 0, 0.8);
      border-radius: 50%;
      background: none;
      transform: translateX(-50%) translateY(-50%);
      animation: rotate 1s linear infinite;
      z-index: map_get($z, modal);
    }
  }

  .views {
    .view {
      &.view-enter-active,
      &.view-leave-active {
        transition: opacity 0.3s ease;
      }
      &.view-enter, &.view-leave-to
      /* .component-fade-leave-active below version 2.1.8 */ {
        opacity: 0;
      }

      // &.view-enter-active {
      //   transition: opacity 0.6s ease-in;
      //   transition-delay: 0.3s;
      // }

      // &.view-leave-active {
      //   transition: opacity 0.3s ease-out;
      // }

      // &.view-enter,
      // &.view-leave-to {
      //   opacity: 0;
      // }
    }
  }
}
</style>
