<template>
  <section :class="['HeroCmp', { intersected: intersected }]">
    <figure
      v-if="
        themeVariant === 'home' ||
        themeVariant === 'article' ||
        themeVariant === 'image'
      "
      class="background"
    >
      <slot name="image" />
    </figure>
    <div class="container h-100">
      <div
        :class="[
          'row h-100',
          {
            'elevated-row':
              themeVariant === 'article' || themeVariant === 'image',
          },
        ]"
      >
        <div
          v-if="themeVariant === 'article'"
          class="box-content col-12 col-md-10 offset-md-1"
        />
        <div
          :class="[
            {
              'col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2':
                themeVariant === 'home',
              'col-12 col-md-10 offset-md-1':
                themeVariant === 'colorful-bg' ||
                themeVariant === 'recipes' ||
                themeVariant === 'image',
              'col-12 col-md-8 offset-md-2': themeVariant === 'article',
              'col-12 col-md-11 offset-md-1':
                themeVariant === 'colorful-bg-with-img',
            },
          ]"
        >
          <div class="content">
            <template v-if="themeVariant === 'home'">
              <component
                :is="dataHeadingTag"
                class="text-color-white text-center"
              >
                <span
                  :class="[{ 'n-heading-2': xsOnly, 'n-heading-1': mdAndUp }]"
                >
                  {{
                    xsOnly
                      ? splittedHeadingTextMobile[0].trim()
                      : splittedHeadingTextDesktop[0].trim()
                  }}
                </span>
                <span class="text-separator n-paragraph-1">
                  <span class="inner-text">
                    {{
                      xsOnly
                        ? splittedHeadingTextMobile[1].trim()
                        : splittedHeadingTextDesktop[1].trim()
                    }}
                  </span>
                </span>
                <span
                  :class="[{ 'n-heading-2': xsOnly, 'n-display': mdAndUp }]"
                >
                  {{
                    xsOnly
                      ? splittedHeadingTextMobile[2].trim()
                      : splittedHeadingTextDesktop[2].trim()
                  }}
                </span>
              </component>
              <div class="scroll-down">
                <svg>
                  <use
                    :href="mdAndDown ? '#icon-caret-down' : '#icon-scroll-down'"
                    stroke="currentColor"
                  />
                </svg>
                <span v-if="lgAndUp" class="a-caption">
                  <slot name="scroll-down" />
                </span>
              </div>
              <div v-intersect.once="onIntersect" class="intersect" />
            </template>
            <template
              v-else-if="
                themeVariant === 'colorful-bg' ||
                themeVariant === 'colorful-bg-with-img' ||
                themeVariant === 'recipes'
              "
            >
              <div class="type-wrapper">
                <slot name="type" />
              </div>
              <hr />
              <div class="title-wrapper">
                <slot name="title" />
              </div>
              <slot
                v-if="themeVariant === 'colorful-bg-with-img'"
                name="image"
              />
            </template>
            <template v-else-if="themeVariant === 'image'">
              <div class="type-wrapper">
                <slot name="type" />
              </div>
              <hr />
              <div class="title-wrapper">
                <slot name="title" />
              </div>
              <div class="breadcrumbs-wrapper">
                <slot name="breadcrumbs" />
              </div>
            </template>
            <template v-else-if="themeVariant === 'article'">
              <slot name="breadcrumbs" />
              <div class="type-wrapper">
                <slot name="type" />
              </div>
              <hr />
              <div class="title-wrapper">
                <slot name="title" />
              </div>
              <div class="date-wrapper">
                <slot name="date" />
              </div>
              <slot name="social-share" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { xsOnly, mdAndUp, mdAndDown, lgAndUp } from '@/helpers/breakpoints.js';
import Intersect from '@frankhoodbs/lib/src/directives/intersect';

export default {
  name: 'HeroCmp',
  directives: {
    Intersect,
  },
  props: {
    dataHeadingTag: {
      type: String,
      default: 'h2',
      validator: function (value) {
        // The value must match one of these strings
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(value) !== -1;
      },
    },
    dataHeadingTextDesktop: {
      type: String,
      default: null,
    },
    dataHeadingTextMobile: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { xsOnly, mdAndUp, mdAndDown, lgAndUp };
  },
  data() {
    return {
      themeVariant: null,
      splittedHeadingTextDesktop: [],
      splittedHeadingTextMobile: [],
      intersected: false,
      hasInsetShadow: false,
    };
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('home')
      ? 'home'
      : this.$el.classList.contains('image')
      ? 'image'
      : this.$el.classList.contains('article')
      ? 'article'
      : this.$el.classList.contains('colorful-bg')
      ? 'colorful-bg'
      : this.$el.classList.contains('colorful-bg-with-img')
      ? 'colorful-bg-with-img'
      : this.$el.classList.contains('recipes')
      ? 'recipes'
      : 'home';

    this.hasInsetShadow = !!this.$el.classList.contains('insetShadow');

    if (this.themeVariant === 'home') {
      this.splittedHeadingTextMobile = this.dataHeadingTextMobile.split('§');
      this.splittedHeadingTextDesktop = this.dataHeadingTextDesktop.split('§');
    }
  },
  methods: {
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.intersected = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.HeroCmp {
  &:not(.noFixedHeight) {
    margin-top: calc(var(--header-height) * -1);
    height: var(--hero-height);
  }

  figure.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: var(--hero-bg-height);
    overflow: hidden;
    z-index: map-get($z, under);

    ::v-deep img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &.home {
    --hero-height: 568px;
    --hero-bg-height: 568px;

    @include media-breakpoint-up(md) {
      --hero-height: 600px;
      --hero-bg-height: 600px;
    }

    @include media-breakpoint-up(lg) {
      --hero-height: 768px;
      --hero-bg-height: 768px;
    }

    @include media-breakpoint-up(xl) {
      --hero-height: 800px;
      --hero-bg-height: 800px;
    }

    position: relative;
    background-color: rgba($color-black, 0.45);

    .content {
      position: relative;
      display: flex;
      justify-content: center;
      height: 100%;
      padding-top: 104px;

      @include media-breakpoint-up(md) {
        align-items: center;
        padding-top: 0;
      }

      .text-separator {
        display: flex;
        justify-content: center;
        overflow: hidden;

        .inner-text {
          display: inline-block;
          position: relative;
          padding-inline: 16px;
          white-space: nowrap;
          overflow: visible;

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            width: 33vw;
            height: 0;
            border-top: 1px solid currentColor;
          }

          &::before {
            left: 0;
            transform: translate(-100%, -50%);
          }

          &::after {
            right: 0;
            transform: translate(100%, -50%);
          }
        }
      }

      .scroll-down {
        position: absolute;
        bottom: 40px;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
        color: $color-white;

        svg {
          width: 24px;
          height: 24px;

          @include media-breakpoint-up(lg) {
            height: 44px;
          }
        }

        span {
          padding-top: 12px;
        }
      }
    }

    /* Animation */
    .intersect {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      height: 1px;
    }

    figure.background {
      img {
        transform: scale(1.2);
        transition: transform 7.5s ease-in-out;
      }
    }

    .content {
      opacity: 0;
      transition: opacity 1.5s ease-in-out;
    }

    &.intersected {
      figure.background {
        img {
          transform: scale(1);
        }
      }

      .content {
        opacity: 1;
        transition-delay: 1.5s;
      }
    }
  }

  &.recipes,
  &.colorful-bg,
  &.colorful-bg-with-img {
    background-color: $color-blue;
    color: $color-white;
    --hero-height: 568px;

    @include media-breakpoint-up(md) {
      --hero-height: 500px;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      padding-bottom: 80px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 120px;
      }

      hr {
        margin: 16px 0;
      }
    }
  }

  &.colorful-bg-with-img {
    display: none;

    @include media-breakpoint-up(md) {
      .title-wrapper {
        padding-right: 230px;
      }
    }

    @include media-breakpoint-up(lg) {
      display: block;
      .title-wrapper {
        padding-right: 300px;
      }

      .content {
        position: relative;

        img {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 228px;
          height: 288px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          @include media-breakpoint-up(xl) {
            width: 302px;
            height: 382px;
          }
        }
      }
    }
  }

  &.image,
  &.article {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-top: var(--hero-padding-top);
    --hero-height: auto;
    --hero-bg-height: 568px;
    --hero-padding-top: 320px;

    @include media-breakpoint-up(md) {
      --hero-bg-height: 472px;
    }

    @include media-breakpoint-up(xl) {
      --hero-padding-top: 480px;
      --hero-bg-height: 640px;
    }

    figure.background {
      z-index: auto;

      img {
        transition: transform 1s;
        transform: scale(1.2);
      }

      &:hover {
        img {
          transform: scale(1);
        }
      }
    }

    .elevated-row {
      position: relative;
      z-index: map-get($z, base);

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: calc(var(--hero-bg-height) - var(--hero-padding-top));
        text-align: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: $color-white;
        color: $color-blue;
        padding: 24px 16px 0;

        @include media-breakpoint-up(md) {
          padding: 24px 24px 0;
        }

        @include media-breakpoint-up(lg) {
          padding: 24px 40px 0;
        }

        hr {
          width: 100%;
          margin: 16px 0;
          color: $color-blue;
        }

        .breadcrumbs-wrapper {
          padding-top: 40px;
        }
      }
    }
  }

  &.image:not(.noFixedHeight),
  &.article:not(.noFixedHeight) {
    min-height: var(--hero-bg-height);
  }

  &.image,
  &.colorful-bg,
  &.colorful-bg-with-img {
    .content {
      .type-wrapper {
        @include responsive-typography-properties(
          $font-primary,
          a-paragraph-2,
          --hero-type-wrapper
        );
      }

      .title-wrapper {
        @include responsive-typography-properties(
          $font-secondary,
          n-heading-1,
          --hero-title-wrapper
        );
      }
    }
  }

  &.recipes {
    .content {
      .title-wrapper {
        @include responsive-typography-properties(
          $font-secondary,
          n-heading-2,
          --hero-title-wrapper
        );
      }
    }
  }

  &.article {
    .elevated-row {
      .box-content {
        position: absolute;
        inset: 0;
        z-index: map-get($z, under);

        &::before {
          content: '';
          display: block;
          height: 100%;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background-color: $color-white;
        }
      }

      .content {
        align-items: flex-start;
        text-align: left;
        border-radius: 0;
        background-color: transparent;

        @include media-breakpoint-up(md) {
          padding: 24px 0 0;
        }

        @include media-breakpoint-up(lg) {
          padding: 24px 0 0;
        }

        .type-wrapper {
          @include responsive-typography-properties(
            $font-primary,
            a-paragraph-1,
            --hero-type-wrapper
          );
          color: $color-grey-800-75;
        }

        hr {
          color: $color-grey-600-75;
        }

        .title-wrapper {
          @include responsive-typography-properties(
            $font-secondary,
            n-heading-2,
            --hero-title-wrapper
          );
        }

        .BreadcrumbsCmp {
          padding-top: 0;
          padding-bottom: 45px;
          align-self: center;

          @include media-breakpoint-up(md) {
            padding-bottom: 69px;
          }

          @include media-breakpoint-up(lg) {
            padding-bottom: 85px;
          }
        }

        .date-wrapper {
          @include responsive-typography-properties(
            $font-primary,
            a-paragraph-3,
            --hero-date-wrapper
          );
          color: $color-grey-600;
          padding-block: 32px 64px;
        }
      }
    }
    &.smallCover {
      --hero-bg-height: 344px;
      --hero-padding-top: 128px;
      @include media-breakpoint-up(md) {
        --hero-bg-height: 320px;
        --hero-padding-top: 136px;
      }
      @include media-breakpoint-up(lg) {
        --hero-bg-height: 288px;
        --hero-padding-top: 168px;
      }
      @include media-breakpoint-up(xl) {
        --hero-bg-height: 376px;
        --hero-padding-top: 172px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.insetShadow {
      .content:after {
        position: absolute;
        content: '';
        display: block;
        right: 0;
        bottom: 0;
        width: 228px;
        height: 288px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: inset 18px 18px 30px rgba(0, 0, 0, 0.44);
        z-index: 5;
        @include media-breakpoint-up(xl) {
          width: 302px;
          height: 382px;
        }
      }
    }
  }
}
</style>
