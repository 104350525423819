<template>
  <header
    :class="['HeaderCmp', { 'not-transparent': !isScrollTop && !scrollDown }]"
    @keydown.esc="onEscKey"
  >
    <div :class="['menu', { hideBar: hideBar }]">
      <div :class="['container', { 'px-r': xsOnly }]">
        <!-- container here or inside the nav.navbar -->
        <nav class="navbar">
          <div class="row g-0 w-100 align-items-center">
            <!-- if the nav.navbar is inside the container add the class w-100 -->
            <!-- the class g-0 is design dependant, if you need the gutter of the grid-column remove it -->
            <div class="col d-flex align-items-center flex-grow-0">
              <!-- LOGO -->
              <a :href="homePageUrl" class="logo" :aria-label="dataWebsiteName">
                <svg>
                  <use :href="`#${dataLogoId}`" />
                </svg>
              </a>
            </div>
            <div v-if="!showMobileMenu" class="skip-link">
              <span class="skip-link-title">Accessibility links</span>
              <a
                :href="`#${dataSkipToContentId}`"
                class="skip-link-anchor"
                aria-label="Skip to content"
                tabindex="0"
              >
                Skip to content
              </a>
            </div>
            <div
              v-if="lgAndUp"
              class="col d-flex align-items-center flex-grow-1"
            >
              <ul class="navbar-nav">
                <slot name="header-menu-items" />
              </ul>
              <div class="contact-us">
                <cta-button-cmp-extended
                  v-if="!hideContactUs"
                  class="btn-primary-reversed btn-small"
                  :data-url="contactUsHref"
                  :data-aria-label="contactUsHeaderLabel"
                >
                  {{ contactUsHeaderLabel }}
                  <template #seo-link>
                    <a :href="contactUsHref">{{ contactUsHeaderLabel }}</a>
                  </template>
                </cta-button-cmp-extended>
              </div>
            </div>
            <div
              class="col d-flex align-items-center justify-content-end flex-grow-1 flex-lg-grow-0"
            >
              <div v-if="lgAndUp && !showLogin" class="profileSpacer" />
              <button
                v-if="lgAndUp"
                class="icon-btn"
                type="button"
                @click="onShowModal"
              >
                <svg>
                  <use href="#icon-country-selection" />
                </svg>
              </button>
              <template v-if="showLogin">
                <div v-if="user && loaded" class="user-dropdown-container">
                  <button
                    :class="['icon-btn user-img', { active: showUserDropdown }]"
                    type="button"
                    data-aria-label="User settings"
                    @click="onToggleUserDropdown($event, !showUserDropdown)"
                  >
                    <img
                      v-if="user.thumbnailURL"
                      width="40"
                      height="40"
                      :src="user.thumbnailURL"
                      alt="User settings"
                    />
                    <svg v-else>
                      <use href="#icon-user" />
                    </svg>
                  </button>
                  <transition name="user-dropdown-wrapper">
                    <div
                      v-if="showUserDropdown && !hideBar"
                      class="user-dropdown-wrapper"
                      @click.stop.self="
                        xsOnly ? onToggleUserDropdown($event, false) : null
                      "
                    >
                      <div
                        :class="['user-dropdown', { 'shadow-soft': mdAndUp }]"
                      >
                        <div class="content">
                          <div class="head">
                            <span>
                              {{ user.firstName }} {{ user.lastName }}
                            </span>
                          </div>
                          <div class="control-panel">
                            <ul>
                              <li>
                                <a :href="dataGigyaProfileUrl">
                                  {{ $store.state.gigya.edit_profile_label }}
                                </a>
                              </li>
                              <li>
                                <a href="#" @click.prevent="onLogout">
                                  {{ $store.state.gigya.logout_label }}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
                <template v-else-if="!loaded">
                  <div class="loading-user" aria-hidden="true">&nbsp;</div>
                </template>
                <a
                  v-else
                  class="icon-btn"
                  :href="dataGigyaLoginUrl"
                  :data-aria-label="$store.state.gigya.logout_label"
                >
                  <svg>
                    <use href="#icon-user" />
                  </svg>
                </a>
              </template>
              <button
                v-if="mdAndDown"
                class="icon-btn"
                type="button"
                aria-label="Open mobile menu"
                @click="onToggleMobileMenu(true)"
              >
                <svg>
                  <use href="#icon-hamburger" />
                </svg>
              </button>
            </div>
          </div>
        </nav>
      </div>
      <transition name="mobile-menu">
        <div
          v-if="mdAndDown && showMobileMenu"
          ref="$mobileMenu"
          class="row g-0 justify-content-end mobile-menu"
          @click.self="onToggleMobileMenu(false)"
        >
          <div class="col-12 col-md-5 right-side">
            <div class="flexWrapper">
              <div>
                <div class="head">
                  <button
                    class="icon-btn"
                    type="button"
                    aria-label="Close mobile menu"
                    @click="onToggleMobileMenu(false)"
                  >
                    <svg>
                      <use href="#icon-close" />
                    </svg>
                  </button>
                </div>
                <nav>
                  <ul class="navbar-nav">
                    <slot name="header-menu-items" />
                  </ul>
                  <cta-button-cmp-extended
                    v-if="!hideContactUs"
                    class="btn-primary-reversed btn-small"
                    :data-url="contactUsHref"
                    :data-aria-label="contactUsHeaderLabel"
                  >
                    {{ contactUsHeaderLabel }}
                    <template #seo-link>
                      <a :href="contactUsHref">{{ contactUsHeaderLabel }}</a>
                    </template>
                  </cta-button-cmp-extended>
                </nav>
              </div>
              <button
                class="icon-btn language-chooser"
                type="button"
                aria-label="Open language selection modal"
                @click="onShowModal"
              >
                <span>{{ localeLabel }}</span>
                <svg>
                  <use href="#icon-country-selection" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
import CtaButtonCmpExtended from './cta-button-cmp-extended';
import { xsOnly, mdAndUp, mdAndDown, lgAndUp } from '@/helpers/breakpoints.js';
import { useThrottleFn } from '@vueuse/core';
import { castPropToType } from '@/helpers/cms-support';
import { mapState } from 'vuex';
import { debug, getCurrentSiteHref, getLocaleLabel } from '@/helpers/utils';
export default {
  name: 'HeaderCmp',
  components: { CtaButtonCmpExtended },
  props: {
    dataWebsiteName: {
      type: String,
      default: '',
    },
    dataLogoId: {
      type: String,
      default: 'icon-logo',
    },
    dataSkipToContentId: {
      type: String,
      default: 'main-content',
    },
    dataGigyaLoginUrl: {
      type: String,
      default: '',
    },
    dataGigyaProfileUrl: {
      type: String,
      default: '',
    },
    dataShowLogin: {
      type: [Boolean, String],
      default: true,
    },
  },
  setup() {
    return { xsOnly, mdAndUp, mdAndDown, lgAndUp, castPropToType };
  },
  data() {
    return {
      showMobileMenu: false,
      scrollY: 0,
      isScrollTop: true,
      scrollDown: false,
      showUserDropdown: false,
      throttledFn: null,
    };
  },
  computed: {
    ...mapState('gigya', ['active', 'loaded', 'user']),
    ...mapState('config', {
      contactUsHref: (state) => state['contact_us_href'],
      contactUsHeaderLabel: (state) => state['contact_us_header_label'],
      hideContactUs: (state) => state['hide_header_contact_us_cta'],
      homePageUrl() {
        return getCurrentSiteHref(this.$store);
      },
    }),
    showLogin() {
      return this.active && this.user;
    },
    hideBar() {
      return this.scrollDown && !this.isScrollTop && !this.showMobileMenu;
    },
    localeLabel() {
      return getLocaleLabel(this.$store);
    },
  },
  watch: {
    mdAndUp: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.showUserDropdown) {
            this.onScrollYHidden(!newVal);
          }
        }
      },
      immediate: true,
    },
    lgAndUp: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          if (this.showMobileMenu) {
            this.onToggleMobileMenu(false);
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.throttledFn = useThrottleFn(this.scrollHandler, 300, true, false);
    window.addEventListener('scroll', this.throttledFn, {
      passive: true,
    });
    window.addEventListener('click', () => {
      this.showUserDropdown = false;
    });
    this.$nextTick(
      () => (this.scrollY = document.body.getBoundingClientRect().top)
    );
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.throttledFn);
  },
  methods: {
    onLogout() {
      window.gigya.accounts.logout();
    },
    scrollHandler() {
      const scrollY = document.body.getBoundingClientRect().top;
      this.scrollDown = scrollY <= this.scrollY;
      this.isScrollTop =
        scrollY * -1 <=
        +getComputedStyle(document.querySelector('.HeaderCmp'))
          .getPropertyValue('--header-height')
          .split('px')[0]
          .trim();
      this.scrollY = scrollY;
    },
    onEscKey() {
      if (this.mdAndDown && this.showMobileMenu) {
        this.onToggleMobileMenu(false);
      }
    },
    onToggleMobileMenu(value) {
      const handler = (v) => {
        this.onScrollYHidden(v);
        this.showMobileMenu = v;
      };
      if (this.$root.isIos) {
        if (value) {
          const scrollY =
            document.documentElement.style.getPropertyValue('--scroll-y');
          const body = document.body;
          body.style.position = 'fixed';
          body.style.top = `-${scrollY}`;
          setTimeout(() => {
            // const scrollYBack = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0'));
            setTimeout(() => {
              handler(value);
            }, 0);
          }, 0);
        } else {
          handler(value);
        }
      } else {
        handler(value);
      }
    },
    onToggleUserDropdown($event, value) {
      if ($event) {
        $event.stopPropagation && $event.stopPropagation();
      }
      if (value) {
        if (this.xsOnly) {
          this.onScrollYHidden(value);
        }
      } else {
        this.onScrollYHidden(value);
      }
      this.showUserDropdown = value;
    },
    onShowModal() {
      if (this.showUserDropdown) {
        this.onToggleUserDropdown({}, false);
      }
      this.$modal.show('language-modal', {
        dataHasBackButton: this.showMobileMenu,
      });
    },
    onScrollYHidden(value) {
      if (value) {
        document.body.classList.add('modal-open');
        (() => {
          let doCheck = () => {
            if (this.$refs.$mobileMenu) {
              this.$_a11Support_disableScrollOuterElement({
                $el: this.$refs.$mobileMenu,
                enable: true,
              });
            } else {
              setTimeout(doCheck, 100);
            }
          };
          doCheck();
        })();
      } else {
        document.body.classList.remove('modal-open');
        this.$_a11Support_clearAllBodyScrollLocks();
      }
    },
  },
};
</script>

<style lang="scss">
:root {
  --header-height: 64px;

  @include media-breakpoint-up(lg) {
    --header-height: 88px;
  }

  @include media-breakpoint-up(xl) {
    --header-height: 96px;
  }
}

body {
  &.modal-open {
    overflow-y: hidden;
  }
}
</style>

<style lang="scss" scoped>
.HeaderCmp {
  --header-bg-color: #{$color-blue};
  --header-padding-inline: 24px;

  @include media-breakpoint-up(lg) {
    --header-padding-inline: 32px;
  }

  @include media-breakpoint-up(xl) {
    --header-padding-inline: 80px;
  }

  &.transparent {
    --header-bg-color: #{$color-transparent};
  }

  &.not-transparent {
    --header-bg-color: #{$color-blue};
  }

  position: relative;
  display: flex;
  flex-direction: column;
  height: var(--header-height);

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    z-index: map_get($z, topbar);

    .navbar {
      position: relative;
      display: flex;
      align-items: center;
      height: var(--header-height);
      padding-inline: var(--header-padding-inline);
      border-radius: 0 0 8px 8px;
      background: var(--header-bg-color, $color-white);

      .logo {
        display: block;

        svg {
          width: 76.41px;
          height: 48px;
          color: $color-white;

          @include media-breakpoint-up(lg) {
            width: 101.02px;
            height: 64px;
          }

          @include media-breakpoint-up(xl) {
            width: 113.65px;
            height: 72px;
          }
        }
      }

      .skip-link {
        display: block;
        position: absolute;
        top: 100%;
        left: 5px; /* Outline counted */
        z-index: map_get($z, nav);

        .skip-link-title {
          display: none;
        }

        .skip-link-anchor {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          background: $color-white;
          color: $color-black;
          white-space: nowrap;
          overflow: hidden;

          &:focus {
            width: auto;
            height: auto;
            border: 1px solid $color-black;
            padding: 10px;
            overflow: visible;
          }
        }
      }

      .contact-us {
        @include media-breakpoint-up(lg) {
          padding-inline: 4px;
        }

        @include media-breakpoint-up(xl) {
          padding-inline: 8px;
        }

        .CtaButtonCmpExtended {
          @include media-breakpoint-up(lg) {
            margin: 0 12px 0 4px;
          }

          @include media-breakpoint-up(xl) {
            margin: 0 32px 0 16px;
          }
        }
      }

      .profileSpacer {
        width: 0;
        height: 0;
        padding-inline: 16px;
      }

      /* code from wasa - start */
      .icon-btn.user-img {
        width: 40px;
        height: 40px;

        &.active {
          box-shadow: 0 0 0 3px rgba($color-black, 0.25);
        }
      }

      .user-dropdown-container {
        position: relative;

        .user-dropdown-wrapper {
          @include media-breakpoint-down(md) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            background-color: $color-grey-800-75;
            opacity: 1;
          }

          &.user-dropdown-wrapper-enter-active,
          &.user-dropdown-wrapper-leave-active {
            transition: opacity 0.3s ease-in-out;

            .user-dropdown {
              @include media-breakpoint-down(md) {
                transition: transform 0.3s ease-in-out;
              }
            }
          }

          &.user-dropdown-wrapper-enter,
          &.user-dropdown-wrapper-leave-to {
            opacity: 0;

            .user-dropdown {
              @include media-breakpoint-down(md) {
                transform: translateY(100%);
              }
            }
          }

          .user-dropdown {
            background-color: $color-white;
            color: $color-black;
            transform: translateY(0);

            @include media-breakpoint-down(md) {
              @include responsive-properties(
                ('padding-inline'),
                $container-padding-x-resp,
                $fallback: $container-padding-x
              );
            }

            @include media-breakpoint-up(md) {
              position: absolute;
              right: 0;
              width: calc((100vw * 25) / 100);
              padding: 0 8px;
              margin: 8px 0 0;
              border: 1px solid $color-grey-200;
              border-radius: 8px;
            }

            @include media-breakpoint-up(lg) {
              margin: 16px 0 0;
            }

            .content {
              width: auto;
              margin: 0;

              .head {
                font-weight: $fh-extra-bold;
                border-bottom: 1px solid $color-grey-400;
                padding: 24px 0;

                @include media-breakpoint-up(md) {
                  padding: 24px 12px;
                }
              }

              .control-panel {
                padding: 8px 0 64px;

                @include media-breakpoint-up(md) {
                  padding: 8px 12px;
                }

                ul {
                  list-style: none;
                  margin: 0;
                  padding: 0;

                  li {
                    padding: 11.5px 0;
                    margin: 0;

                    a {
                      color: inherit;

                      &:hover {
                        color: $color-blue;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      /* code from wasa - end */

      &::after {
        content: '';
        position: absolute;
        top: var(--header-height);
        left: 0;
        width: 100vw;
        height: 1px;
        border-top: 1px solid var(--header-border-color, transparent); /* change transparent here if you want a border always */
      }
    }

    ::v-deep ul.navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 32px;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        margin-left: auto;
        padding: 0;
      }

      & > li {
        font-size: 0;
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
          padding-inline: 8px;
          margin: 0;
        }

        @include media-breakpoint-up(xl) {
          padding-inline: 24px;
        }

        & > a {
          position: relative;
          display: block;
          padding: 8px 0;
          color: $color-white;
          text-decoration: none;
          transition: color 0.2s ease-out;
          @include responsive-typography-properties($font-primary, a-support);

          &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-image: linear-gradient($color-white, $color-white);
            background-size: 0 100%, auto;
            background-repeat: no-repeat;
            background-position: left bottom;
            transition: all 0.2s ease-out;

            @include media-breakpoint-up(lg) {
              background-position: center bottom;
            }
          }

          &:hover,
          &:active,
          &.is-active {
            &::after {
              background-size: 100% 100%, auto;
            }
          }

          &:hover {
            color: $color-grey-400-75;

            &::after {
              background-image: linear-gradient(
                $color-grey-400-75,
                $color-grey-400-75
              );
            }
          }

          &.is-active,
          &:active {
            color: $color-grey-200-50;

            &::after {
              background-image: linear-gradient(
                $color-grey-900-75,
                $color-grey-900-75
              );
            }
          }
        }
      }
    }

    .icon-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin-left: 16px;
      border-radius: 50%;
      overflow: visible;

      svg {
        width: 16px;
        height: 16px;
        color: $color-white;
      }

      &:hover {
        svg {
          color: $color-grey-400-75;
        }
      }
    }

    /* code from wasa - start */
    .mobile-menu {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: $color-grey-800-75;
      opacity: 1;

      &.mobile-menu-enter-active,
      &.mobile-menu-leave-active {
        transition: opacity 300ms ease-out;

        .right-side {
          transition: transform 300ms ease-out;
        }
      }

      &.mobile-menu-enter,
      &.mobile-menu-leave-to {
        opacity: 0;

        .right-side {
          transform: translateX(100%);
        }
      }

      .right-side {
        //transform: translateX(0);
        background-color: $color-blue;

        .flexWrapper {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          height: 100%;
        }

        .head {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 30px 20px;

          .icon-btn {
            width: 24px;
            height: 24px;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        .CtaButtonCmpExtended {
          margin-left: 32px;
        }

        .language-chooser {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: flex-end;
          width: auto;
          height: 24px;
          margin: 0 20px 45px 0;
          border-radius: 0;

          span {
            display: inline-block;
            padding-right: 16px;
            color: $color-white;
            @include responsive-typography-properties($font-primary, a-support);
          }

          svg {
            width: 24px;
            height: 24px;
          }

          &:hover {
            span {
              color: $color-grey-400-75;
            }
          }
        }
      }
    }
    /* code from wasa - end */
  }

  /* Animation */
  .menu.hideBar {
    transform: translate(0, -100%);
  }

  .loading-user {
    position: relative;
    width: 16px;
    height: 16px;
    margin-left: 16px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px !important;
      height: 16px !important;
      min-width: 0;
      min-height: 0;
      background: none;
      color: rgba(255, 255, 255, 1);
      border: 2px solid;
      border-radius: 50%;
      border-right-color: rgba(0, 0, 0, 0.8);
      transform: translateX(-50%) translateY(-50%);
      animation: rotate 1s linear infinite;
    }
  }
}
</style>
