/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    header_theme: '',
    hide_header_contact_us_cta: false,
    contact_us_header_label: '',
    contact_us_href: '',
    date_format: 'DDD',
    decimal_separator: '',
    canada_site_language: '',
    canada_fr_page: '',
    canada_en_page: '',
    contact_us_cta: null,
    download_label: '',
    back_label: '',
    step_label: '',
    home_page_url: '',
    ordered_languages: '',
    banner: {
      global: {
        banner_title: '',
        banner_text: '',
        banner_desktop_image: '',
        banner_mobile_image: '',
        banner_desktop_image_alt: '',
        banner_mobile_image_alt: '',
        banner_link: {
          href: '',
          label: '',
          target: '',
          rel: '',
        },
      },
      products: {
        banner_title: '',
        banner_text: '',
        banner_desktop_image: '',
        banner_mobile_image: '',
        banner_desktop_image_alt: '',
        banner_mobile_image_alt: '',
        banner_link: {
          href: '',
          label: '',
          target: '',
          rel: '',
        },
      },
      services: {
        banner_title: '',
        banner_text: '',
        banner_desktop_image: '',
        banner_mobile_image: '',
        banner_desktop_image_alt: '',
        banner_mobile_image_alt: '',
        banner_link: {
          href: '',
          label: '',
          target: '',
          rel: '',
        },
      },
      recipes: {
        banner_title: '',
        banner_text: '',
        banner_desktop_image: '',
        banner_mobile_image: '',
        banner_desktop_image_alt: '',
        banner_mobile_image_alt: '',
        banner_link: {
          href: '',
          label: '',
          target: '',
          rel: '',
        },
      },
      news_and_media: {
        banner_title: '',
        banner_text: '',
        banner_desktop_image: '',
        banner_mobile_image: '',
        banner_desktop_image_alt: '',
        banner_mobile_image_alt: '',
        banner_link: {
          href: '',
          label: '',
          target: '',
          rel: '',
        },
      },
    },
    banner_override: {
      override: {
        banner_title: '',
        banner_text: '',
        banner_desktop_image: '',
        banner_mobile_image: '',
        banner_desktop_image_alt: '',
        banner_mobile_image_alt: '',
        banner_link: {
          href: '',
          label: '',
          target: '',
          rel: '',
        },
      },
    },
  },
  actions,
  mutations,
  getters,
};
