<template>
  <modal-cmp
    class="LanguageModalCmp"
    data-name="language-modal"
    :data-has-inner-scroll="true"
  >
    <div class="modal-content">
      <span class="title n-paragraph-1">
        <slot name="title" />
      </span>
      <div class="row">
        <div
          v-for="(item, i) in orderedLanguages"
          :key="`continent-${i}-${_uid}`"
          class="col-6 col-lg-3"
        >
          <div class="row">
            <div class="col-12">
              <span class="continent a-paragraph-1">
                {{ item.continent ? item.continent : '&nbsp;' }}
              </span>
            </div>
            <div
              v-for="(region, j) in item.regions"
              :key="`region-${j}-${_uid}`"
              class="col-12"
            >
              <a
                :href="getSiteHref($store, region)"
                :class="[
                  'language-item d-flex flex-column align-items-center justify-content-center',
                  {
                    active:
                      currentLangObj.country_label === region.country_label &&
                      currentLangObj.language_code === region.language_code,
                  },
                ]"
              >
                <span class="a-paragraph-2">
                  {{ region.country_label }}
                </span>
                <span class="a-caption">
                  {{ region.tongue_label }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal-cmp>
</template>

<script>
import ModalCmp from './modal-cmp';
import { castPropToType } from '@/helpers/cms-support';
import {
  debug,
  getCurrentLangConf,
  getSiteHref,
  getWorldAreasConfs,
} from '@/helpers/utils';

export default {
  name: 'LanguageModalCmp',
  components: { ModalCmp },
  setup() {
    return { castPropToType };
  },
  computed: {
    currentLangObj() {
      return getCurrentLangConf(this.$store);
    },
    orderedLanguages() {
      return getWorldAreasConfs(this.$store);
    },
  },
  methods: {
    getSiteHref,
  },
};
</script>

<style lang="scss" scoped>
.LanguageModalCmp {
  --modal-bg-color: #{$color-blue};
  --modal-content-separator-color: #{$color-grey-800-50};
  --modal-close-color: #{$color-white};
  --modal-close-hover-color: #{$color-grey-400-75};

  .modal-content {
    padding: 32px 30px;
    background-color: $color-blue;
    color: $color-white;

    @include media-breakpoint-up(md) {
      padding: 40px 16px;
    }

    @include media-breakpoint-up(lg) {
      padding: 32px 24px 64px;
    }

    .title {
      display: block;
      text-align: center;
      padding: 0 10px 40px;

      @include media-breakpoint-up(md) {
        padding: 0 0 40px;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 0 48px;
      }

      @include media-breakpoint-up(xl) {
        padding: 0 0 56px;
      }
    }

    .continent {
      display: block;
      text-align: center;
      padding-bottom: 24px;

      @include media-breakpoint-up(md) {
        padding-bottom: 40px;
      }
    }

    .language-item {
      margin-bottom: 14px;
      padding: 8px 12px;
      border-radius: 4px;
      border: 2px solid transparent;
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      @include media-breakpoint-up(md) {
        padding: 12px 16px;
      }

      &:hover {
        border-color: $color-white;
      }

      &.active {
        background-color: $color-grey-800-50;
      }

      &:last-child {
        margin-bottom: 38px;

        @include media-breakpoint-up(md) {
          margin-bottom: 54px;
        }

        @include media-breakpoint-up(lg) {
          margin-bottom: 14px;
        }
      }
    }
  }
}
</style>
