<template>
  <div v-if="ready" class="NewsAndMediaListTabPage">
    <div class="row">
      <!-- filter section -->
      <div v-if="isTopicsPopulated" class="col-12">
        <span class="filters-label text-color-grey-800 a-support">
          {{ list_filter_by_label }}
        </span>
      </div>
      <div v-if="isTopicsPopulated" class="col-auto filters-wrapper">
        <dropdown-cmp-extended
          data-tag="button"
          :data-trigger-theme-classes="[{ selected: filters.length > 0 }]"
          :data-menu-theme-classes="['shadow-soft']"
        >
          {{ list_topic_label }}
          <template v-if="filters.length > 0">({{ filters.length }})</template>
          <template #after-icon>
            <svg class="icon-caret-down">
              <use href="#icon-caret-down" />
            </svg>
          </template>
          <template #dropdown-menu>
            <input-checkbox-cmp-extended
              v-model.trim="computedFilters"
              data-name="filters"
              :data-aria-label="list_topic_label"
              class="custom direction-column"
              :data-override-boolean-behaviour="true"
              :data-checkbox-list="
                topics.map((e) => ({
                  id: e.slug,
                  value: e.slug,
                  label: e.label,
                }))
              "
            />
          </template>
        </dropdown-cmp-extended>
      </div>
    </div>
    <slot v-if="!asyncData" />
    <template v-else>
      <div class="row cards-wrapper">
        <div
          v-for="item in list"
          :key="`card-${item.slug}-${_uid}`"
          class="col-12 col-md-6 col-lg-4"
        >
          <card-cmp :data-href="item.href" class="normal">
            <template #seo-link>
              <a :href="item.href">{{ item.title }}</a>
            </template>
            <template #image>
              <image-cmp
                width="1062"
                height="596"
                :data-desktop-image="item.list_image"
                :data-image-alt="item.list_image_alt"
              />
            </template>
            <template #tags>
              <tag-cmp
                v-for="topic in item.topics"
                :key="`card-${item.slug}-${_uid}-${topic.slug}`"
              >
                {{ topic.label }}
              </tag-cmp>
            </template>
            <template #category>
              <h3>{{ item.category.title }}</h3>
            </template>
            <template #title>
              <h2>{{ item.title }}</h2>
            </template>
            <template #date>
              <time :datetime="item.publication_start_iso">
                <beautiful-date
                  :data-datetime="item.publication_start_iso"
                  :data-locale="currentLang.language_code"
                  :data-format="$store.state.config.date_format"
                >
                  {{ item.publication_start }}
                </beautiful-date>
              </time>
            </template>
            <template #cta>
              <cta-button-cmp-extended class="btn-text" data-tag="button">
                {{ news_and_media_read_more_label }}
                <template #after-icon>
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
              </cta-button-cmp-extended>
            </template>
          </card-cmp>
        </div>
      </div>
    </template>
    <div v-if="paginationConfig && paginationConfig.length > 0" class="row">
      <div class="col-12 pagination-wrapper">
        <pagination-cmp
          data-tag="a"
          :data-pages="paginationConfig"
          :data-emit="true"
          :data-active-page-index="pagination.current_page - 1"
          @click="handlePagination"
        >
          <template #seo-pagination>
            <slot name="news-seo-pagination" />
          </template>
        </pagination-cmp>
      </div>
    </div>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { getCurrentLangConf } from '@/helpers/utils';

import { mapActions, mapState } from 'vuex';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'NewsAndMediaListTabPage',
  props: {
    dataCurrentCategory: { type: String, required: true },
  },
  setup() {
    return { castPropToType };
  },
  computed: {
    ...mapState('config', ['date_format']),
    ...mapState('newsAndMedia', [
      'list_filter_by_label',
      'list_topic_label',
      'news_and_media_read_more_label',
      'topics',
      'filters',
      'pagination',
      'pages',
    ]),
    isTopicsPopulated() {
      return this.topics.length > 0;
    },
    currentLang() {
      return getCurrentLangConf(this.$store);
    },
    currentCategory() {
      return this.dataCurrentCategory
        ? this.castPropToType(this.dataCurrentCategory, 'object')
        : null;
    },
    ready() {
      return (
        !!this.currentCategory &&
        this.pages &&
        Array.isArray(this.pages[this.currentCategory.slug])
      );
    },
    asyncData() {
      return this.ready && this.pages[this.currentCategory.slug].length > 0;
    },
    list() {
      return (
        (this.pages &&
          this.pagination.current_page &&
          this.pages[this.currentCategory.slug].find((p) => {
            return p.page_number === this.pagination.current_page;
          })?.data) ||
        []
      );
    },
    paginationConfig() {
      const pages = [];
      for (let i = 0; i < this.pagination.final_page; i++) {
        let page = i + 1;
        pages.push({
          label: page,
          pageNumber: +page,
          href: '?page=' + page,
          target: '_self',
        });
      }

      return pages;
    },
    computedFilters: {
      get() {
        return this.filters;
      },
      set(value) {
        this.setFilters(value);
      },
    },
  },
  watch: {
    filters: {
      async handler(newVal, oldVal) {
        if (newVal && oldVal) {
          if (this.$route?.params?.pageNumber) {
            this.$router.push({
              name: 'index',
              params: {
                currentCategorySlug: this.currentCategory.slug,
              },
            });
          } else {
            const payload = {
              category_slug: this.currentCategory.slug,
            };
            if (newVal.length) {
              payload.filters = newVal;
            }
            await this.setPages(payload);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('newsAndMedia', ['setFilters', 'setPages']),
    handlePagination(payload) {
      if (+payload.pageNumber === +this.pagination.current_page) return;
      const targetAnchor =
        this.$parent.$parent.$refs.$targetScroll?.$el ||
        this.$parent.$parent.$refs.$targetScroll;
      const elemRect = targetAnchor.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const offsetMargin =
        +window.getComputedStyle(targetAnchor).height.split('px')[0] -
        +getComputedStyle(document.documentElement)
          .getPropertyValue('--header-height')
          .trim()
          .split('px')[0];
      const offset = elemRect.top - bodyRect.top;
      let internalPadding = window.getComputedStyle(targetAnchor).paddingTop;

      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement;

      if (internalPadding.indexOf('px')) {
        internalPadding = parseInt(internalPadding.split('px')[0], 10);
      } else {
        internalPadding = 0;
      }

      anime({
        targets: scrollElement,
        scrollTop: offset + internalPadding + offsetMargin,
        duration: 350,
        easing: 'easeInOutQuad',
        complete: () => {
          anime({
            targets: scrollElement,
            scrollTop: offset + internalPadding + offsetMargin + 1,
            duration: 1,
            complete: () => {
              this.$router.push({
                name: 'index',
                params: {
                  currentCategorySlug: this.currentCategory.slug,
                  pageSlug: this.pagination.page,
                  pageNumber: payload.pageNumber,
                },
              });
            },
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.NewsAndMediaListTabPage {
  .filters-label {
    display: block;
    padding: 40px 0 16px;
  }

  .filters-wrapper {
    padding-bottom: 80px;
  }

  .cards-wrapper {
    row-gap: 40px;

    @include media-breakpoint-up(md) {
      row-gap: 64px;
    }
  }

  .pagination-wrapper {
    padding-top: 50px;
  }
}
</style>
