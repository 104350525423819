<template>
  <input-file-cmp
    class="InputFileCmpExtended"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </input-file-cmp>
</template>

<script>
import { InputFileCmp } from '@frankhoodbs/lib';

export default {
  name: 'InputFileCmpExtended',
  components: { InputFileCmp },
};
</script>

<style lang="scss" scoped>
.InputFileCmpExtended.form-group__wrapper {
  --form-element-padding: 0;
  --form-element-error-color: #{$color-informative-red};

  ::v-deep .form-group {
    .form__label {
      @include override-responsive-typography-properties(
        $font-primary,
        a-support,
        --form-element-label
      );
      --form-element-label-color: #{$color-grey-800};
    }

    .form-control__wrapper {
      .form-control-file {
        @include override-responsive-typography-properties(
          $font-primary,
          a-body,
          --form-element-input
        );

        &.dropbox-wrapper {
          --form-element-input-border-color: #{$color-grey-600-50};
          --form-element-placeholder-color: #{$color-grey-600};
          --form-element-icon-color: #{$color-grey-600};
          --form-element-file-drag-drop-text-padding: 0 0 16px;
          --form-element-file-drag-over-border-color: #{$color-grey-800-50};
          --form-element-file-drag-over-bg-color: #{$color-grey-200-50};
          --form-element-file-drag-over-color: #{$color-grey-900-50};

          .drag-drop-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .CtaButtonCmpExtended {
              --cta-button-text-align: center;
              padding: 0;
              word-break: break-word;

              span.text {
                white-space: normal;
              }
            }
          }

          &.drag-over {
            --form-element-icon-color: #{$color-grey-900-50};
          }

          &.dirty {
            --form-element-file-border-color: #{$color-grey-600-50};
          }
        }
      }
    }
  }
}
</style>
