<template>
  <section
    :class="[
      'ChefContestForm pb-l',
      {
        hasCustomFont: dataHasCustomFont !== false,
      },
    ]"
  >
    <div class="container pt-r pb-r">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <cta-button-cmp-extended
            class="btn-text text-color-grey-600 a-caption backBtn"
            :data-url="dataBackPageUrl"
            :data-aria-label="dataBackPageLabel"
          >
            {{ dataBackPageLabel }}
            <template slot="before-icon">
              <svg>
                <use href="#icon-caret-left" />
              </svg>
            </template>
          </cta-button-cmp-extended>
        </div>
      </div>
    </div>
    <hero-cmp class="image pt-r noFixedHeight pb-r">
      <template slot="type">
        <slot name="overtitle"></slot>
      </template>
      <template slot="title">
        <slot name="title"></slot>
      </template>
      <template slot="breadcrumbs">
        <slot name="subtitle"></slot>
      </template>
    </hero-cmp>
    <div class="container">
      <form-cmp class="ContactFormFiscalInfoCmp">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-xl-8">
            <div class="row align-items-end">
              <div class="mt-7 col-12 col-md-6">
                <input-text-cmp-extended
                  v-model.trim="$v.name.$model"
                  data-name="name"
                  :data-placeholder="placeholders.name"
                  :data-error-occurred="$v.name.$error"
                  :data-errors="[
                    {
                      condition: !$v.name.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.name.serverError,
                      message: serverErrors.name,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="nameLabel" />
                  </template>
                </input-text-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6">
                <input-text-cmp-extended
                  v-model.trim="$v.lastName.$model"
                  data-name="lastName"
                  :data-placeholder="placeholders.lastName"
                  :data-error-occurred="$v.lastName.$error"
                  :data-errors="[
                    {
                      condition: !$v.lastName.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.lastName.serverError,
                      message: serverErrors.lastName,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="lastNameLabel" />
                  </template>
                </input-text-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6">
                <select-cmp-extended
                  v-model.trim="$v.market.$model"
                  data-name="market"
                  :data-option-list="markets"
                  :data-placeholder="placeholders.market"
                  :data-error-occurred="$v.market.$error"
                  :data-errors="[
                    {
                      condition: !$v.market.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.market.serverError,
                      message: serverErrors.market,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="marketLabel" />
                  </template>
                  <template #icon>
                    <svg>
                      <use href="#icon-caret-down" />
                    </svg>
                  </template>
                </select-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6">
                <input-text-cmp-extended
                  v-model.trim="$v.city.$model"
                  data-name="city"
                  :data-placeholder="placeholders.city"
                  :data-error-occurred="$v.city.$error"
                  :data-errors="[
                    {
                      condition: !$v.city.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.city.serverError,
                      message: serverErrors.city,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="cityLabel" />
                  </template>
                </input-text-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6">
                <input-text-cmp-extended
                  v-model.trim="$v.email.$model"
                  data-name="email"
                  :data-placeholder="placeholders.email"
                  :data-error-occurred="$v.email.$error"
                  :data-errors="[
                    {
                      condition: !$v.email.email,
                      message: dataValidEmail,
                    },
                    {
                      condition: !$v.email.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.email.serverError,
                      message: serverErrors.email,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="emailLabel" />
                  </template>
                </input-text-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6">
                <input-text-cmp-extended
                  v-model.trim="$v.confirmEmail.$model"
                  data-name="confirmEmail"
                  :data-placeholder="placeholders.confirmEmail"
                  :data-error-occurred="$v.confirmEmail.$error"
                  :data-errors="[
                    {
                      condition: !$v.confirmEmail.sameAs,
                      message: dataSameEmail,
                    },
                    {
                      condition: !$v.confirmEmail.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.confirmEmail.serverError,
                      message: serverErrors.confirmEmail,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="confirmEmailLabel" />
                  </template>
                </input-text-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6">
                <input-text-cmp-extended
                  v-model.trim="$v.phone.$model"
                  data-name="phone"
                  :data-placeholder="placeholders.phone"
                  :data-error-occurred="$v.phone.$error"
                  :data-errors="[
                    {
                      condition: !$v.phone.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.phone.serverError,
                      message: serverErrors.phone,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="phoneLabel"></slot>
                  </template>
                </input-text-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6">
                <input-text-cmp-extended
                  v-model.trim="$v.restaurant.$model"
                  data-name="restaurant"
                  :data-placeholder="placeholders.restaurant"
                  :data-error-occurred="$v.restaurant.$error"
                  :data-errors="[
                    {
                      condition: !$v.restaurant.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.restaurant.serverError,
                      message: serverErrors.restaurant,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="restaurantLabel"></slot>
                  </template>
                </input-text-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6">
                <input-text-cmp-extended
                  v-model.trim="$v.website.$model"
                  data-name="website"
                  :data-placeholder="placeholders.website"
                  :data-error-occurred="$v.website.$error"
                  :data-errors="[
                    {
                      condition: !$v.website.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.website.serverError,
                      message: serverErrors.website,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="websiteLabel"></slot>
                  </template>
                </input-text-cmp-extended>
              </div>

              <div class="mt-7 col-12 col-md-6">
                <input-text-cmp-extended
                  v-model.trim="$v.position.$model"
                  data-name="position"
                  :data-placeholder="placeholders.position"
                  :data-error-occurred="$v.position.$error"
                  :data-errors="[
                    {
                      condition: !$v.position.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.position.serverError,
                      message: serverErrors.position,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="positionLabel"></slot>
                  </template>
                </input-text-cmp-extended>
              </div>

              <div class="mt-7 col-12">
                <textarea-cmp-extended
                  v-model.trim="$v.recipe.$model"
                  data-name="recipe"
                  :data-placeholder="placeholders.recipe"
                  :data-rows="7"
                  :data-resizable="false"
                  :data-error-occurred="$v.recipe.$error"
                  :data-errors="[
                    {
                      condition: !$v.recipe.serverError,
                      recipe: serverErrors.recipe,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="recipeLabel"></slot>
                  </template>
                </textarea-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6">
                <select-cmp-extended
                  v-model.trim="$v.how.$model"
                  data-name="how"
                  :data-option-list="hows"
                  :data-placeholder="placeholders.how"
                  :data-error-occurred="$v.how.$error"
                  :data-errors="[
                    {
                      condition: !$v.how.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.how.serverError,
                      message: serverErrors.how,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="howLabel"></slot>
                  </template>
                  <template #icon>
                    <svg>
                      <use href="#icon-caret-down" />
                    </svg>
                  </template>
                </select-cmp-extended>
              </div>
              <div class="mt-7 col-12 col-md-6"></div>
              <div class="mt-9 col-12">
                <wysiwyg-cmp>
                  <div class="text-color-blue n-heading-3">
                    <slot name="uploadTitle"></slot>
                  </div>
                  <div class="styledLists pt-s">
                    <slot name="uploadSubtitle"></slot>
                  </div>
                </wysiwyg-cmp>
              </div>
              <div class="mt-9 col-12">
                <input-file-cmp-extended
                  ref="$file"
                  v-model.trim="$v.file.$model"
                  class="dropbox"
                  data-name="file"
                  :data-error-occurred="$v.file.$error"
                  :data-errors="[
                    {
                      condition: !$v.file.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.file.validSize,
                      message: dataValidSize,
                    },
                    {
                      condition: !$v.file.validFormat,
                      message: dataValidFormat,
                    },
                    {
                      condition: !$v.file.serverError,
                      message: serverErrors.file,
                    },
                  ]"
                >
                  <template #label>
                    <slot name="fileLabel"></slot>
                  </template>
                  <template #icon>
                    <svg>
                      <use href="#icon-upload" />
                    </svg>
                  </template>
                  <template #drag-text>
                    <slot name="fileLabelDrag"></slot>
                    <cta-button-cmp-extended class="btn-text">
                      <slot name="fileLabelCta"></slot>
                    </cta-button-cmp-extended>
                  </template>
                  <template #drop-text>
                    <slot name="fileLabelDrop"></slot>
                  </template>
                  <template>
                    <slot name="fileLabelPlaceholder"></slot>
                  </template>
                </input-file-cmp-extended>
              </div>
              <div class="mt-9 col-12">
                <input-checkbox-cmp-extended
                  v-model.trim="consent1"
                  :data-checkbox-list="[
                    {
                      labelOn: dataConsentFirst,
                      labelOff: '	',
                    },
                  ]"
                  data-name="checkbox1"
                  class="custom direction-column"
                  :data-error-occurred="$v.consent1.$invalid"
                  :data-errors="[
                    {
                      condition: !$v.consent1.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.consent1.serverError,
                      message: serverErrors.consent1,
                    },
                  ]"
                ></input-checkbox-cmp-extended>
              </div>
              <div class="col-12">
                <input-checkbox-cmp-extended
                  v-model.trim="consent2"
                  data-name="checkbox2"
                  :data-checkbox-list="[
                    {
                      labelOn: dataConsentSecond,
                      labelOff: '	',
                    },
                  ]"
                  class="custom direction-column"
                  :data-error-occurred="$v.consent2.$invalid"
                  :data-errors="[
                    {
                      condition: !$v.consent2.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.consent2.serverError,
                      message: serverErrors.consent2,
                    },
                  ]"
                ></input-checkbox-cmp-extended>
              </div>
            </div>
            <div class="row pt-r justify-content-center">
              <div class="col-auto">
                <recaptcha-cmp
                  :data-sitekey="dataRecaptchaApiKey"
                  data-aria-label="recaptcha"
                  :data-error-occurred="$v.recaptcha.$error"
                  :data-errors="[
                    {
                      condition: !$v.recaptcha.required,
                      message: dataRequiredField,
                    },
                    {
                      condition: !$v.recaptcha.serverError,
                      message: serverErrors.recaptcha,
                    },
                  ]"
                  @verify="recaptchaVerify"
                />
              </div>
            </div>
            <div class="row mt-7">
              <div class="col-12 text-center">
                <submit-cmp-extended
                  :data-theme-classes="['reversed']"
                  :data-disabled="disableSubmit || submitted"
                  :data-error-occurred="
                    !!globalErrors.length || !!Object.keys(serverErrors).length
                  "
                  :data-errors="globalErrors"
                  @click-submit="doSubmit"
                >
                  <template>
                    <slot name="submitLabel"></slot>
                  </template>
                  <template #after-icon>
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                </submit-cmp-extended>
              </div>
            </div>
            <div class="row mt-9">
              <div class="col-12">
                <slot name="requiredFieldsLabel"></slot>
              </div>
            </div>
          </div>
        </div>
      </form-cmp>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import {
  maxLength,
  maxValue,
  required,
  email,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators';
import { FormCmp } from '@frankhoodbs/lib';
import FormElementsSupport from '@frankhoodbs/lib/src/mixins/form-elements-support';
import { mapActions, mapGetters, mapState } from 'vuex';
import { debug, currentLang } from '@/helpers/utils';
import axios from 'axios';
import { castPropToType } from '@/helpers/cms-support';

const FIELDS = [
  'name',
  'lastName',
  'market',
  'city',
  'email',
  'confirmEmail',
  'phone',
  'restaurant',
  'website',
  'position',
  'recipe',
  'how',
  'file',
  'consent1',
  'consent2',
  'recaptcha',
];

Vue.use(Vuelidate);

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'ChefContestForm',
  components: { FormCmp },
  mixins: [FormElementsSupport],
  props: {
    dataHasCustomFont: {
      type: [String, Boolean],
      default: false,
    },
    dataPreselectedMarket: {
      type: String,
      required: true,
    },
    dataThankYouPage: {
      type: String,
      required: true,
    },
    dataBackPageLabel: {
      type: String,
      required: true,
    },
    dataBackPageUrl: {
      type: String,
      required: true,
    },
    dataRecaptchaApiKey: {
      type: String,
      required: true,
    },
    dataValidSize: {
      type: String,
      required: true,
    },
    dataValidFormat: {
      type: String,
      required: true,
    },
    dataValidEmail: {
      type: String,
      required: true,
    },
    dataRequiredField: {
      type: String,
      required: true,
    },
    dataSameEmail: {
      type: String,
      required: true,
    },
    dataConsentFirst: {
      type: String,
      required: true,
    },
    dataConsentSecond: {
      type: String,
      required: true,
    },
    dataRequestMethod: {
      type: String,
      required: true,
    },
    dataApiUrl: {
      type: String,
      required: true,
    },
    dataHandledStatusCodeErrors: {
      type: Array,
      default: () => [400, 401, 403],
    },
    dataHows: {
      type: [String, Array],
      default: () => {
        return [];
      },
    },
    dataMarkets: {
      type: [String, Array],
      default: () => {
        return [];
      },
    },
    dataPlaceholders: {
      type: [String, Object],
      default: () => {
        return {
          name: 'code placeholder',
          lastName: 'code placeholder',
          market: 'code placeholder',
          city: 'code placeholder',
          email: 'youremail@email.com',
          confirmEmail: 'youremail@email.com',
          phone: '+39123456789',
          restaurant: 'code placeholder',
          website: 'code placeholder',
          position: 'code placeholder',
          recipe: 'code placeholder',
          how: 'code placeholder',
        };
      },
    },
  },
  setup() {
    return {
      castPropToType,
    };
  },

  data() {
    const data = {};
    FIELDS.forEach((fieldName) => {
      data[fieldName] = undefined;
    });
    data.how = '';
    data.market = this.dataPreselectedMarket;
    data.consent1 = false;
    data.consent2 = false;
    data.recaptcha = undefined;
    return data;
  },

  computed: {
    placeholders() {
      return this.dataPlaceholders
        ? this.castPropToType(this.dataPlaceholders, 'object')
        : null;
    },
    langObj() {
      return currentLang(this.$store.state.config.ordered_languages);
    },
    hows() {
      return this.dataHows ? this.castPropToType(this.dataHows, 'array') : null;
    },
    markets() {
      return this.dataMarkets
        ? this.castPropToType(this.dataMarkets, 'array')
        : null;
    },
    disableSubmit() {
      if (this.dataSubmitEnabledOnMounted) {
        return false;
      } else {
        if (this.$v && this.$v.$anyError) {
          return true;
        }

        return false;
      }
    },
  },
  created() {
    if (this.dataHasCustomFont !== false) {
      var link = document.createElement('link');
      link.href =
        'https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap';
      link.rel = 'stylesheet';

      document.getElementsByTagName('head')[0].appendChild(link);
    }
  },
  methods: {
    recaptchaVerify(response) {
      this.recaptcha = response;
    },
    handleSuccessCall() {
      window.location.href = this.dataThankYouPage;
    },
    async doSubmit() {
      this.$v.$touch();

      if (!this.submitted && !this.disableSubmit && !this.$v.$invalid) {
        this.submitted = true;
        this.loading = true;
        this.globalErrors = [];
        this.toastErrors = [];

        try {
          const formData = new FormData();
          formData.set('name', this.name);
          formData.set('lastName', this.lastName);
          formData.set('market', this.market);
          formData.set('city', this.market);
          formData.set('email', this.email);
          if (this.phone) {
            formData.set('phone', this.phone);
          }
          formData.set('restaurant', this.restaurant);
          if (this.website) {
            formData.set('website', this.website);
          }
          formData.set('position', this.position);
          formData.set('recipe', this.recipe);
          const file = this.file ?? '';
          formData.set('file', file);
          formData.set('consent1', this.consent1);
          formData.set('consent2', this.consent2);
          formData.set('recaptcha', this.recaptcha);

          let axiosConfig = {
            method: this.dataRequestMethod,
            url: this.dataApiUrl,
            data: formData,
          };
          const response = await axios(axiosConfig);

          // Implement 200
          // Effettuare  nell'if tutti i controlli del caso per verificare che il payload di risposta
          // ricevuto sia conforme a quello che ci aspettiamo
          if (response.data) {
            this.handleSuccessCall(response);
          } else {
            this.$_formElementsSupport_handleApiError(response);
          }
        } catch (e) {
          // Controlliamo se siamo entrati nella catch per una risposta del server non di successo
          // se response non è incluso o non abbiamo lo status code aspettato allora si è rotto
          // qualcosa lato codice nostro lo gestiamo subito
          const hasResponse =
            Object.getOwnPropertyNames(e).includes('response');
          if (
            !hasResponse ||
            (hasResponse &&
              !this.dataHandledStatusCodeErrors.includes(e.response.status))
          ) {
            // 500 or unknown status code
            this.$_formElementsSupport_handleUnknownError(e);
          } else {
            this.$_formElementsSupport_handleErrors(e);
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
  validations() {
    const validations = {};
    FIELDS.forEach((fieldName) => {
      validations[fieldName] = {};
      if (fieldName !== 'phone' && fieldName !== 'website') {
        validations[fieldName].required = required;
      }

      validations[fieldName].serverError = checkServerErrors(`${fieldName}`);
    });

    validations.email = {
      ...validations.email,
      email,
    };
    validations.confirmEmail = {
      ...validations.confirmEmail,
      sameAs: sameAs('email'),
    };
    validations.file = {
      ...validations.file,
      validSize: (val) => !helpers.req(val) || val.size <= 10 * 1024 * 1024,
      validFormat: (val) =>
        !helpers.req(val) ||
        val.type === 'application/zip' ||
        val.type === 'application/x-zip-compressed',
    };
    validations.consent1 = {
      ...validations.consent1,
      required: (value) => value === true,
    };
    validations.consent2 = {
      ...validations.consent2,
      required: (value) => value === true,
    };
    return validations;
  },
};
</script>

<style lang="scss" scoped>
.ChefContestForm {
  .backBtn {
    --cta-button-icon-width: 14px;
    --cta-button-icon-height: 14px;
  }

  ::v-deep .WysiwygCmp {
    .styledLists {
      li {
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 13px;
      }
    }
  }
  ::v-deep .InputCheckboxCmp {
    a {
      text-decoration: underline;
    }
    &.form-group__wrapper.custom
      .form-group
      .form-check__wrapper
      .form-check
      .form-check-label:before {
      top: var(--form-check-margin-inline, 12px);
    }
    &.form-group__wrapper.custom
      .form-group
      .form-check__wrapper
      .form-check
      .form-check-label:after {
      top: var(--form-check-margin-inline, 12px);
    }
  }
}
</style>
