<template>
  <div
    :class="[
      'CardProductCmp h-100',
      {
        nohover: dataHref === '',
      },
    ]"
  >
    <component
      :is="dataHref !== '' ? 'a' : 'div'"
      :href="dataHref !== '' ? dataHref : null"
      :target="dataHref !== '' ? dataTarget : null"
      class="card-link h-100"
    >
      <div
        v-if="
          hasSlot($slots.title, $scopedSlots.title) &&
          !(themeVariant === 'range' || themeVariant === 'product')
        "
        class="title-wrapper"
      >
        <slot name="title" />
      </div>
      <figure
        v-if="hasSlot($slots.image, $scopedSlots.image)"
        :class="['image-wrapper']"
      >
        <slot name="image" />
      </figure>
      <div v-if="hasSlot($slots.tag, $scopedSlots.tag)" class="tag-wrapper">
        <slot name="tag" />
      </div>
      <div
        v-if="
          hasSlot($slots.title, $scopedSlots.title) &&
          (themeVariant === 'range' || themeVariant === 'product')
        "
        :class="[
          'title-wrapper ',
          { withTag: hasSlot($slots.tag, $scopedSlots.tag) },
        ]"
      >
        <slot name="title" />
      </div>
      <div
        v-if="
          hasSlot($slots.text, $scopedSlots.text) || themeVariant === 'range'
        "
        class="text-wrapper a-paragraph-3"
      >
        <slot name="text" />
      </div>
      <div
        v-if="
          !hasSlot($slots.text, $scopedSlots.text) &&
          (dataQuantity || dataMeasureUnitValue || dataMeasureUnit)
        "
        class="text-wrapper a-paragraph-3"
      >
        <p>
          {{
            customDecimalSeparator(
              dataQuantity,
              $store.state.config.decimal_separator
            )
          }}
          <span v-if="dataMeasureUnitValue">
            x{{
              customDecimalSeparator(
                dataMeasureUnitValue,
                $store.state.config.decimal_separator
              )
            }}
          </span>
          {{ dataMeasureUnit }}
        </p>
      </div>
      <div v-if="themeVariant !== 'product'" class="half-bg"></div>
    </component>
  </div>
</template>

<script>
import { hasSlot } from '@/helpers/cms-support';
import { customDecimalSeparator } from '@/helpers/utils';

export default {
  name: 'CardProductCmp',
  props: {
    dataHref: {
      type: String,
      default: '#',
    },
    dataTarget: {
      type: String,
      default: null,
    },
    dataQuantity: {
      type: [String, Number],
      default: null,
    },
    dataMeasureUnitValue: {
      type: [String, Number],
      default: null,
    },
    dataMeasureUnit: {
      type: [String, Number],
      default: null,
    },
  },
  setup() {
    return { hasSlot, customDecimalSeparator };
  },
  data() {
    return {
      themeVariant: null,
    };
  },
  mounted() {
    this.themeVariant = this.$el.classList.contains('range')
      ? 'range'
      : this.$el.classList.contains('product')
      ? 'product'
      : '';
  },
};
</script>

<style lang="scss" scoped>
.CardProductCmp {
  .card-link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .tag-wrapper {
    margin-top: 24px;
  }

  .title-wrapper {
    @include responsive-typography-properties($font-secondary, n-heading-3);
    color: $color-blue;
  }

  .half-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    border-radius: 8px;
    z-index: map-get($z, under);

    @include media-breakpoint-up(md) {
      height: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $color-blue-10;
      opacity: 1;
      transition: opacity 1s ease-out;
      border-radius: 8px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(232, 235, 241, 1) 26.56%,
        rgba(232, 235, 241, 0) 100%
      );
      opacity: 0;
      transition: opacity 1s ease-out;
      border-radius: 8px;
    }
  }

  .text-wrapper {
    max-width: 460px;
    padding: 0 16px 16px 16px;
    margin: 16px auto 0 auto;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding-bottom: 40px;
    }
  }

  .image-wrapper {
    position: relative;
    width: 100%;
    height: 164px;
    padding: 0 16px;
    margin: 24px auto 0 auto;
    border-radius: 8px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      height: 280px;
      padding: 0 40px;
    }

    @include media-breakpoint-up(lg) {
      height: 320px;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      transition: transform 1s;
      transform: scale(1);
    }
  }

  &:not(.nohover):hover {
    .image-wrapper {
      img {
        transform: scale(0.9);
      }
    }

    .half-bg {
      &::after {
        opacity: 0;
      }
      &::before {
        opacity: 1;
      }
    }
  }

  &.range {
    .title-wrapper {
      @include responsive-typography-properties($font-secondary, n-paragraph-1);
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
      text-align: center;
    }
  }

  &.product {
    .card-link {
      justify-content: space-between;
      padding: 24px;
      background-color: $color-white;
      border: 2px solid $color-blue-10;
      border-radius: 8px;
      transition: border-color 1s ease;
    }

    .title-wrapper {
      @include responsive-typography-properties($font-primary, a-paragraph-1);
      color: $color-black;
      padding-top: 24px;
      text-align: center;

      &.withTag {
        padding-top: 8px;
      }
    }

    .image-wrapper {
      position: relative;
      height: 0;
      padding-top: 82.76%;
      margin: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 1s;
        transform: scale(1.2);
      }
    }

    .text-wrapper {
      @include responsive-typography-properties($font-primary, a-paragraph-3);
      color: $color-grey-800;
      padding: 0;
      margin: 8px auto 0;
    }

    &:hover {
      .card-link {
        border-color: $color-blue;
      }

      .image-wrapper {
        img {
          transform: scale(1);
        }
      }
    }
  }

  &.animationForFilledImgs {
    .image-wrapper {
      img {
        transform: scale(1.2);
      }
    }
    &:hover {
      .image-wrapper {
        img {
          transform: scale(1);
        }
      }
    }
  }
  &.animationForTransparentsImgs {
    .image-wrapper {
      img {
        transform: scale(1);
      }
    }
    &:hover {
      .image-wrapper {
        img {
          transform: scale(0.9);
        }
      }
    }
  }
  > div {
    cursor: default !important;
  }
}
</style>
