import { render, staticRenderFns } from "./chef-contest-form.vue?vue&type=template&id=e0a70326&scoped=true"
import script from "./chef-contest-form.vue?vue&type=script&lang=js"
export * from "./chef-contest-form.vue?vue&type=script&lang=js"
import style0 from "./chef-contest-form.vue?vue&type=style&index=0&id=e0a70326&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0a70326",
  null
  
)

export default component.exports